import * as React from "react"
import styled from "styled-components"
import Button from "../../components/Reusable/Button"
import Image from "../../components/image"

const MaintenancePage = () => {
  const [image, setImage] = React.useState(false)

  React.useLayoutEffect(() => {
    function responsiveImage() {
      if (window.matchMedia("(max-width: 450px)").matches) {
        setImage(true)
      } else {
        setImage(false)
      }
    }
    window.addEventListener("resize", responsiveImage)
    responsiveImage()
    return () => window.removeEventListener("resize", responsiveImage)
  }, [])

  return (
    <Styled>
      <div>
        {!image ? (
          <div className="img">
            <Image imgName="maintenance.png" alt="maintenance" />
          </div>
        ) : (
          <div className="img">
            <Image imgName="maintenancePhone.png" alt="maintenance" />
          </div>
        )}

        <h3>Pemeliharaan Sistem</h3>
        <p>
          Kami akan kembali dalam beberapa saat lagi. Jika kamu memiliki
          pertanyaan dapat hubungi kami{" "}
          <span style={{ textDecoration: "underline" }}>
            <a
              href="https://wa.me/6281383736364"
              target="_blank"
              rel="external nofollow noopener noreferrer"
            >
              di sini
            </a>
          </span>
          .
        </p>
        <div style={{ paddingBottom: "30px" }}>
          <Button to="/">Kembali ke halaman utama</Button>
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  width: 100%;
  text-align: center;
  color: #4f473f;

  h3 {
    font-size: 32px;
  }

  p {
    font-size: 24px;
    padding: 0px 20px;
  }

  .img {
    width: 100vh;
    margin: 50px auto;
  }

  @media only screen and (max-width: 991.98px) {
    .img {
      width: 70vh;
      padding: 0px 45px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  @media only screen and (max-width: 511.98px) {
    height: 80vh;

    .img {
      width: 45vh;
      padding: 0px 10px;
      margin: auto;
      margin-bottom: 35px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      padding: 0px 20px;
    }
  }

  @media only screen and (max-width: 300px) {
    height: 80vh;

    .img {
      width: auto;
      padding: 0px 10px;
      margin: auto;
      margin-bottom: 35px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      padding: 0px 20px;
    }
  }
`

export default MaintenancePage
